<li class="dropdown nav-item">
  <a
    role="button"
    href="javascript:void(0);"
    class="dropdown-toggle nav-link"
    (click)="handleDialog()"
    matTooltip="{{ 'TOB_BAR.LABEL_HELP' | translate }}"
    #toggleButton4
  >
    <i class="zmdi zmdi-help"></i>
    <div class="notify"></div>
  </a>
  <ul
    [ngClass]="{
      'dropdown-menu slideUp2': true,
      show: isOpen
    }"
    #menu4
  >
    <li class="body">
      <ul class="menu list-unstyled py-2">
        <li>
          <a href="https://cloud.paques.id/resources-support" target="_blank">
            <div class=""><i class="f-20 zmdi zmdi-help"></i></div>
            <div class="menu-info">
              <h4>{{ 'TOB_BAR.LABEL_HELP' | translate }}</h4>
            </div>
          </a>
        </li>

        <li>
          <a href="javascript:void(0);" (click)="openModalTemplate(videotutorialTemplate)">
            <div class="">
              <i class="f-20 zmdi zmdi-play-circle"></i>
            </div>
            <div class="menu-info">
              <h4>{{ 'TOB_BAR.LABEL_VIDEO_TUTORIAL' | translate }}</h4>
            </div>
          </a>
        </li>
        <li>
          <a href="javascript:void(0);" (click)="openModalTemplate(creditTemplates)">
            <div class="">
              <i class="f-20 zmdi zmdi-assignment"></i>
            </div>
            <div class="menu-info">
              <h4>{{ 'TOB_BAR.LABEL_TEAM_CREDITS' | translate }}</h4>
            </div>
          </a>
        </li>
      </ul>
    </li>
  </ul>
</li>

<ng-template #creditTemplate let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="title f-16" id="defaultModalLabel">{{ 'TOB_BAR.LABEL_TEAM_CREDITS' | translate }}</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="position: unset">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
        <div class="col-lg-2 align-center mt-2">
          <img src="assets/images/logo_paques.svg" alt="Paques Logo" width="30px" />
        </div>
        <div class="col-lg-10">
          <p class="f-18 mb-0 fw-bold">Paques Data Studio</p>
          <p class="f-12 mb-0 fw-bold">Version v3.5.1-build-f-c43433874-20241217</p>

          <div class="mt-3">
            <ul class="list-inline f-12 mb-0">
              <li class="list-inline-item">{{ 'TOB_BAR.CN' | translate }}</li>
              <li class="list-inline-item fw-bold">{{licenseInformation.company}}</li>
              <li class="list-inline-item fw-bold">({{licenseInformation.email}})</li>
            </ul>
            <ul class="list-inline f-12 mb-0">
              <li class="list-inline-item">{{ 'TOB_BAR.LCA' | translate }}</li>
              <li class="list-inline-item fw-bold">{{licenseInformation.createdAt}}
              <li class="list-inline-item">{{ 'TOB_BAR.AEO' | translate }} </li>
              <li class="list-inline-item fw-bold"> {{licenseInformation.expiredAt}}.</li>
            </ul>
            <ul class="list-inline f-12 mb-0">
              <li class="list-inline-item">{{ 'TOB_BAR.TU' | translate }}</li>
              <li class="list-inline-item fw-bold">{{licenseInformation.totalUsers}}</li>
              <li class="list-inline-item fw-bold">{{ 'TOB_BAR.US' | translate }}.</li>
            </ul>
          </div>



          <div class="copyright my-2 f-10 mt-4">
            <hr>
            {{ 'TOB_BAR.C' | translate }} &copy;
            <script>
              document.write(new Date().getFullYear());
            </script>
            <a href="https://paques.id/" target="_blank">PT Informatika Solusi Bisnis</a>
          </div>
        </div>
    </div>
  </div>
</ng-template>

<ng-template #videotutorialTemplate let-c="close" let-d="dismiss">
  <div class="modal-header">
    <h5 class="title f-16">Video Tutorial</h5>
    <button type="button" class="close" aria-label="Close" (click)="d('Cross click')" style="position: unset">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body text-center">
    <iframe
      width="560"
      height="315"
      [src]="urlLoad(dataRoot.url)"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
    <p class="mb-5 f-14 text-center">{{ dataRoot.title }}</p>
  </div>
</ng-template>
